import Input from 'components/input'
import LegacyEmailInput from './email-input.legacy'

const EmailInput = ({ legacyStyle, ref, ...props }) =>
  legacyStyle ? (
    <LegacyEmailInput ref={ref} type="email" {...props} />
  ) : (
    <Input ref={ref} type="email" {...props} />
  )

export default EmailInput
