import { Global, css } from '@emotion/react'
import Icon from 'components/icon.legacy'
import * as _ from 'modules/util'
import * as R from 'ramda'

const AnimatedLogo = ({ className }) => (
  <div className={className} css={animationStyles}>
    <Icon height="61" name="clinikoLogoC" viewBox="0 0 60 61" width="60" />
    <Icon height="64" name="clinikoLogoL" viewBox="0 0 15 64" width="15" />
    <Icon height="61" name="clinikoLogoI" viewBox="0 0 16 61" width="16" />
    <Icon height="45" name="clinikoLogoN" viewBox="0 0 43 45" width="43" />
    <Icon height="61" name="clinikoLogoI" viewBox="0 0 16 61" width="16" />
    <Icon height="64" name="clinikoLogoK" viewBox="0 0 36 64" width="36" />
    <Icon height="46" name="clinikoLogoO" viewBox="0 0 50 46" width="50" />
    <Icon
      name="clinikoLogoAvatar"
      css={{ animation: 'sign-in-logo-avatar 2.5s' }}
      height="85"
      width="99"
    />
    <Global styles={avatarKeyframesStyles} />
    {R.range(1, 8).map(logoKeyframeStyles)}
  </div>
)

const animationStyles = _.thru(
  R.range(1, 8),
  R.map(num => ({
    [`svg:nth-of-type(${num})`]: {
      animation: `logo-${num} 2.5s`,
    },
  }))
)

const avatarKeyframesStyles = css`
  @keyframes sign-in-logo-avatar {
    0%,
    40% {
      opacity: 0;
      transform: translateX(60px);
    }
    50%,
    57.5% {
      opacity: 1;
      transform: translateX(0px);
    }
  }
`
const logoKeyframeStyles = number => (
  <Global
    key={number}
    styles={css`
      @keyframes logo-${number} {
        0%,
        ${number * 5}% {
          opacity: 0;
          transform: translateY(-60px);
        }
        ${10 + number * 5}%, ${57.5 + number * 2.5}% {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    `}
  />
)

export default AnimatedLogo
