import * as S from 'apps/cliniko/style'
import Button from 'components/button.legacy'
import Icon from 'components/icon.legacy'
import Prose from 'components/prose.legacy'
import Sanitize from 'components/sanitize.legacy'
import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

const Message = ({
  className,
  dismissible,
  icon: iconIn,
  isLeaving,
  message,
  ref,
  remove,
  sanitize,
  type,
}) => {
  const isAlert = _.thru(type, R.either(R.equals('error'), R.equals('warning')))

  const icon =
    iconIn || L.get(type, { error: 'alert', info: 'info', success: 'tick', warning: 'alert' })

  return (
    <div
      aria-live={_.unless(isAlert, 'polite')}
      className={className}
      css={containerStyles({ type })}
      ref={ref}
      role={isAlert ? 'alert' : 'status'}>
      <div css={iconContainerStyles({ type })}>
        <Icon css={iconStyles({ type })} name={icon} width="30" />
      </div>
      <div css={contentContainerStyles}>
        <Prose as={sanitize ? Sanitize : 'p'} css={contentStyles({ type })}>
          {message}
        </Prose>
        {!!dismissible && (
          <Button compact css={buttonStyles({ type })} disabled={isLeaving} onClick={remove}>
            Okay
          </Button>
        )}
      </div>
    </div>
  )
}

const containerStyles = ({ type }) => ({
  display: 'flex',
  position: 'relative',
  ...S.legacy.space({ pl: 2, pr: 3, py: 1.5 }),
  background: _.cond(
    [type === 'success', S.legacy.colors.success],
    [type === 'warning', S.legacy.colors.warning],
    [type === 'error', S.legacy.colors.error],
    [S.legacy.colors.secondary]
  ),
  '&:focus': {
    outline: 'none',
  },
})

const iconContainerStyles = ({ type }) => ({
  position: 'relative',
  transform: 'translateY(0)',
  width: S.rem(60),
  ...S.legacy.space({ my: -1.5, mr: 3, ml: -2 }),
  background: _.cond(
    [type === 'success', S.legacy.colors.successShade],
    [type === 'error', S.legacy.colors.errorDark],
    [type === 'warning', S.legacy.colors.warningDark],
    [S.legacy.colors.secondaryDarker]
  ),
})

const iconStyles = ({ type }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transformOrigin: 'center bottom',
  fill: _.cond(
    [type === 'success', S.legacy.colors.successLine],
    [type === 'error', 'white'],
    [type === 'warning', S.legacy.colors.bodyFont],
    [S.legacy.colors.white]
  ),
})

const contentContainerStyles = {
  display: 'flex',
  flex: '1 1 auto',
  minWidth: 0,
  minHeight: 0,
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'baseline',
  ...S.legacy.space({ ml: -2 }),
}

const contentStyles = ({ type }) => [
  S.breakWords,
  S.legacy.space({ mr: 1 }),
  _.unless(['warning', 'success'].includes(type), {
    color: 'white',
    'a, .link': {
      color: 'white',
    },
  }),
]

const buttonStyles = ({ type }) =>
  _.cond(
    [
      type === 'warning',
      {
        background: S.legacy.colors.warning,
        borderColor: S.legacy.colors.warningLine,
        '&:hover': {
          background: S.legacy.colors.warningShade,
        },
      },
    ],
    [
      type === 'error',
      {
        background: S.legacy.colors.error,
        borderColor: S.legacy.colors.errorLine,
        color: 'white',
        '&:hover': {
          background: S.legacy.colors.errorShade,
        },
      },
    ],
    [
      type === 'success',
      {
        background: S.legacy.colors.successLighterShade,
        borderColor: S.legacy.colors.successLine,
        '&:hover': {
          background: S.legacy.colors.successShade,
        },
      },
    ],
    [
      {
        background: S.legacy.colors.secondaryDarker,
        borderColor: S.legacy.colors.secondaryDarker,
        color: 'white',
        '&:hover': {
          background: S.legacy.colors.secondaryDarker,
        },
      },
    ]
  )

export default Message
